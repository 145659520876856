import { useSelector } from "react-redux";
import { selectors as NODE_SELECTORS } from "../ducks/node";

import { NODE_OS } from "../lib/utils/constants";

function useNodeOS() {
  const nodeOS = useSelector(NODE_SELECTORS.getNodeOS);
  const isLinux = nodeOS === NODE_OS.LINUX;
  const isWindows = nodeOS === NODE_OS.WINDOWS;

  return { isLinux, isWindows };
}

export default useNodeOS;
