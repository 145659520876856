import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Select, Form } from "antd";

import ConnectionMethodLabel from "../NodeChannelFormV5/Labels/ConnectionMethodLabel";

import useNodeChannelConfig from "../../hooks/useNodeChannelConfig";

import { CONNECTION_METHOD_INPUT, QUICKSTREAM_METHOD_V2, SELECT_DEFAULT_AUTO } from "../../lib/utils/constants";
import { required } from "../../lib/utils/formValidation";
import {
  DEFAULT_IN_UDP_UNICAST,
  DEFAULT_IN_UDP_MULTICAST,
  DEFAULT_IN_SRT_LISTENER,
  DEFAULT_IN_SRT_CALLER,
  DEFAULT_IN_QS_DIRECT,
} from "../../lib/utils/defaultValues/inputURLDefaultValues";

import useNodeOS from "../../hooks/useNodeOS";

import { FormV5Context } from "../NodeChannelFormV5";

const { Item } = Form;
const { Option } = Select;

function InputTranscodingConnectionMethod({ disabled, getFieldValue, isBackup, name, setConnectionMethod }) {
  const form = useContext(FormV5Context);
  const { isLinux } = useNodeOS();

  const { setFieldValue } = form;

  const CONNECTION_METHOD_INPUT_ARRAY = Object.values({
    ...CONNECTION_METHOD_INPUT,
    ...(isBackup ? {} : { inQSDirect: QUICKSTREAM_METHOD_V2.inQSDirect }),
  });

  const { isEnginePlayout } = useNodeChannelConfig();

  const getInputTypePrefix = () => {
    if (isEnginePlayout && !isBackup) {
      return ["liveSource", "mainInput"];
    }

    if (isEnginePlayout && isBackup) {
      return ["liveSource", "backupInput"];
    }

    if (isBackup) {
      return ["backupInput"];
    }

    return ["mainInput"];
  };

  const handleChangeConnectionMethod = (value) => {
    const inputPrefix = getInputTypePrefix();

    const inputFormData = getFieldValue(inputPrefix);

    switch (value) {
      case QUICKSTREAM_METHOD_V2.inQSDirect.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_QS_DIRECT;

        setFieldValue(inputPrefix, inputFormData);

        break;
      case CONNECTION_METHOD_INPUT.inUdpUnicast.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_UDP_UNICAST;

        setFieldValue(inputPrefix, inputFormData);
        break;
      case CONNECTION_METHOD_INPUT.inUdpMulticast.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_UDP_MULTICAST;
        if (isLinux) {
          inputFormData.inputStreamCfg.interfaceIp = SELECT_DEFAULT_AUTO.value;
        } else {
          delete inputFormData.inputStreamCfg.interfaceIp;
        }

        setFieldValue(inputPrefix, inputFormData);
        break;
      case CONNECTION_METHOD_INPUT.inSrtListener.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_SRT_LISTENER;

        setFieldValue(inputPrefix, inputFormData);
        break;
      case CONNECTION_METHOD_INPUT.inSrtCaller.value:
        delete inputFormData.inputStreamCfg;
        inputFormData.inputStreamCfg = DEFAULT_IN_SRT_CALLER;

        setFieldValue(inputPrefix, inputFormData);
        break;
      default:
        break;
    }

    setConnectionMethod(value);
  };

  return (
    <Item name={name} label={<ConnectionMethodLabel />} rules={[required]}>
      <Select disabled={disabled} onChange={handleChangeConnectionMethod}>
        {CONNECTION_METHOD_INPUT_ARRAY.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Item>
  );
}

InputTranscodingConnectionMethod.propTypes = {
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  isBackup: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  setConnectionMethod: PropTypes.func,
};

InputTranscodingConnectionMethod.defaultProps = {
  disabled: false,
  getFieldValue: null,
  isBackup: false,
  setConnectionMethod: null,
};

export default InputTranscodingConnectionMethod;
