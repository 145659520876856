import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Badge } from "antd";

import IncompleteConfig from "../IncompleteConfig";

import themeColor from "../../lib/style/theme";

const { Ribbon } = Badge;

const InputHLSElement = ({ label, handleClick, url, title, incomplete }) => {
  const noIncomplete = !incomplete || (incomplete && incomplete.length === 0);

  return (
    <Ribbon
      text={<IncompleteConfig incomplete={incomplete} color="#FFFFFF" />}
      color={noIncomplete ? themeColor.green : themeColor.orange}
    >
      <StyledContainer onClick={handleClick}>
        <StyledAsideType>{label}</StyledAsideType>
        <StyledBoldText>{title}</StyledBoldText>
        <StyledDiv>
          <StyledText>
            <FormattedMessage id="InputHLSElement.url" defaultMessage="URL" />:
          </StyledText>
          <StyledBoldText>{url}</StyledBoldText>
        </StyledDiv>
      </StyledContainer>
    </Ribbon>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.span`
  margin-right: 5px;
`;

const StyledBoldText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105px;
  margin: 0 0 15px 30px;
  padding: 10px;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
`;

const StyledAsideType = styled.span`
  font: bold 11px Sans-Serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: ${themeColor.orange};
  color: #fff;
  padding: 5px;
  line-height: 24px;
  position: absolute;
  top: 104px;
  left: -1px;
  transform-origin: 0 0;
  transform: rotate(270deg);
  width: 105px;
  text-align: center;
  border: 1px solid ${themeColor.grayBorder};
  border-radius: 2px;
`;

InputHLSElement.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  incomplete: PropTypes.arrayOf(PropTypes.number),
};

InputHLSElement.defaultProps = {
  url: null,
  incomplete: null,
};

export default InputHLSElement;
