import React from "react";
import PropTypes from "prop-types";
import { Col, Form, Select, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getEnvidiaHWEncodersSelector } from "../../ducks/nodeDevices";

import globalTranslations from "../../lib/translations";
import { required } from "../../lib/utils/formValidation";
import {
  NV_265_LEVEL_SELECTOR,
  NV_265_PRESET_SELECTOR,
  NV_265_TIER_SELECTOR,
  NV_265_RATE_CONTROL_SELECTOR,
} from "../../lib/utils/constants";

import InputNumberField from "../Fields/InputNumberField";

const { Item } = Form;

export default function Nvidia265VideoCodec({ prefix, disabled }) {
  const { formatMessage } = useIntl();

  const NVIDIA_HW_ENCODERS_SELECTOR = useSelector(getEnvidiaHWEncodersSelector);

  return (
    <>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv265Level"]}
          label={<FormattedMessage id="Nvidia265VideoCodec.nv265Level" defaultMessage="Level" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_265_LEVEL_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv265Preset"]}
          label={<FormattedMessage id="Nvidia265VideoCodec.nv265Preset" defaultMessage="Preset" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_265_PRESET_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv265Tier"]}
          label={<FormattedMessage id="Nvidia265VideoCodec.nv265Tier" defaultMessage="Tier" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_265_TIER_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv265RateControl"]}
          label={<FormattedMessage id="Nvidia265VideoCodec.nv265RateControl" defaultMessage="Rate control" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_265_RATE_CONTROL_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <InputNumberField
          disabled={disabled}
          name={[...prefix, "nv265RCLookahead"]}
          label={<FormattedMessage id="Nvidia265VideoCodec.nv265RCLookahead" defaultMessage="Rate control lookahead" />}
          rules={[required]}
          min={0}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv265SelectGPU"]}
          label={<FormattedMessage id="Nvidia265VideoCodec.nv265SelectGPU" defaultMessage="Select GPU" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NVIDIA_HW_ENCODERS_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv265StrictGOP"]}
          valuePropName="checked"
          label={<FormattedMessage id="Nvidia265VideoCodec.nv265StrictGOP" defaultMessage="Strict GoP" />}
        >
          <Switch
            disabled={disabled}
            checkedChildren={formatMessage(globalTranslations.on)}
            unCheckedChildren={formatMessage(globalTranslations.off)}
          />
        </Item>
      </Col>
    </>
  );
}

Nvidia265VideoCodec.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disabled: PropTypes.bool.isRequired,
};
