import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Select, Form } from "antd";

import ConnectionMethodLabel from "../../NodeChannelFormV4/Labels/ConnectionMethodLabel";

import {
  CONNECTION_METHOD_INPUT,
  QUICKSTREAM_METHOD_V2,
  LICENSES_TYPES,
  SELECT_DEFAULT_AUTO,
} from "../../../lib/utils/constants";
import { required } from "../../../lib/utils/formValidation";
import {
  DEFAULT_IN_UDP_UNICAST,
  DEFAULT_IN_UDP_MULTICAST,
  DEFAULT_IN_SRT_LISTENER,
  DEFAULT_IN_SRT_CALLER,
  DEFAULT_INPUT_BACKUP_TRIGGERS,
  DEFAULT_IN_QS_DIRECT,
} from "../../../lib/utils/defaultValues/inputURLDefaultValues";
import useNodeVersion from "../../../hooks/useNodeVersion";
import useNodeOS from "../../../hooks/useNodeOS";

const { Item } = Form;
const { Option } = Select;

const InputConnectionMethod = ({ disabled, getFieldValue, isBackup, name, setConnectionMethod, setFieldsValue }) => {
  const { isV5Node } = useNodeVersion();
  const CONNECTION_METHOD_INPUT_ARRAY = Object.values({
    ...CONNECTION_METHOD_INPUT,
    ...(isBackup ? {} : { inQSDirect: QUICKSTREAM_METHOD_V2.inQSDirect }),
  });
  const licenseId = isV5Node ? LICENSES_TYPES.CLOUD : getFieldValue(["licenseId"]);
  const isCloudLicense = licenseId === LICENSES_TYPES.CLOUD;
  const { isLinux } = useNodeOS();

  const handleChangeConnectionMethod = (value) => {
    const input = getFieldValue(["input"]);
    switch (value) {
      case QUICKSTREAM_METHOD_V2.inQSDirect.value:
        delete input.mainConnection;
        input.mainConnection = DEFAULT_IN_QS_DIRECT;

        setFieldsValue({
          input,
        });

        break;
      case CONNECTION_METHOD_INPUT.inUdpUnicast.value:
        if (isBackup) {
          delete input.backupConnection;
          delete input.backupTriggers;
          input.backupConnection = DEFAULT_IN_UDP_UNICAST;
          input.backupTriggers = DEFAULT_INPUT_BACKUP_TRIGGERS;
        } else {
          delete input.mainConnection;
          input.mainConnection = DEFAULT_IN_UDP_UNICAST;
        }

        setFieldsValue({
          input,
        });
        break;
      case CONNECTION_METHOD_INPUT.inUdpMulticast.value:
        if (isBackup) {
          delete input.backupConnection;
          delete input.backupTriggers;

          input.backupConnection = DEFAULT_IN_UDP_MULTICAST;
          input.backupTriggers = DEFAULT_INPUT_BACKUP_TRIGGERS;

          if (isLinux) {
            input.backupConnection.interfaceIp = SELECT_DEFAULT_AUTO.value;
          } else {
            delete input.backupConnection.interfaceIp;
          }
        } else {
          delete input.mainConnection;
          input.mainConnection = DEFAULT_IN_UDP_MULTICAST;

          if (isLinux) {
            input.mainConnection.interfaceIp = SELECT_DEFAULT_AUTO.value;
          } else {
            delete input.mainConnection.interfaceIp;
          }
        }

        setFieldsValue({
          input,
        });
        break;
      case CONNECTION_METHOD_INPUT.inSrtListener.value:
        if (isBackup) {
          delete input.backupConnection;
          delete input.backupTriggers;
          input.backupConnection = DEFAULT_IN_SRT_LISTENER;
          input.backupTriggers = DEFAULT_INPUT_BACKUP_TRIGGERS;
        } else {
          delete input.mainConnection;
          input.mainConnection = DEFAULT_IN_SRT_LISTENER;
        }

        setFieldsValue({
          input,
        });
        break;
      case CONNECTION_METHOD_INPUT.inSrtCaller.value:
        if (isBackup) {
          delete input.backupConnection;
          delete input.backupTriggers;
          input.backupConnection = DEFAULT_IN_SRT_CALLER;
          input.backupTriggers = DEFAULT_INPUT_BACKUP_TRIGGERS;
        } else {
          delete input.mainConnection;
          input.mainConnection = DEFAULT_IN_SRT_CALLER;
        }

        setFieldsValue({
          input,
        });
        break;
      default:
        break;
    }

    setConnectionMethod(value);
  };

  return (
    <Item name={name} label={<ConnectionMethodLabel />} rules={[required]}>
      <Select disabled={disabled} onChange={handleChangeConnectionMethod}>
        {CONNECTION_METHOD_INPUT_ARRAY.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            disabled={!isCloudLicense && option.value === QUICKSTREAM_METHOD_V2.inQSDirect.value}
          >
            {!isCloudLicense && option.value === QUICKSTREAM_METHOD_V2.inQSDirect.value ? (
              <>
                {option.label} (
                <FormattedMessage
                  id="general.availableCloudLicense"
                  defaultMessage="Available only for cloud license"
                />
                )
              </>
            ) : (
              option.label
            )}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

InputConnectionMethod.propTypes = {
  disabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  isBackup: PropTypes.bool,
  name: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  setConnectionMethod: PropTypes.func,
  setFieldsValue: PropTypes.func,
};

InputConnectionMethod.defaultProps = {
  disabled: false,
  getFieldValue: null,
  isBackup: false,
  setFieldsValue: null,
  setConnectionMethod: null,
};

export default InputConnectionMethod;
