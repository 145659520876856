import React from "react";
import PropTypes from "prop-types";
import { Col, Form, Select, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { useSelector } from "react-redux";
import { getEnvidiaHWEncodersSelector } from "../../ducks/nodeDevices";

import InputNumberField from "../Fields/InputNumberField";

import {
  NV_264_CODER_SELECTOR,
  NV_264_LEVEL_SELECTOR,
  NV_264_PRESET_SELECTOR,
  NV_264_PROFILE_SELECTOR,
  NV_264_RATE_CONTROL_SELECTOR,
} from "../../lib/utils/constants";
import { required } from "../../lib/utils/formValidation";
import globalTranslations from "../../lib/translations";

const { Item } = Form;

export default function Nvidia264VideoCodec({ prefix, disabled }) {
  const { formatMessage } = useIntl();

  const NVIDIA_HW_ENCODERS_SELECTOR = useSelector(getEnvidiaHWEncodersSelector);

  return (
    <>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv264Coder"]}
          label={<FormattedMessage id="Nvidia264VideoCodec.nv264Coder" defaultMessage="Coder" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_264_CODER_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv264Level"]}
          label={<FormattedMessage id="Nvidia264VideoCodec.nv264Level" defaultMessage="Level" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_264_LEVEL_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv264Preset"]}
          label={<FormattedMessage id="Nvidia264VideoCodec.nv264Preset" defaultMessage="Preset" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_264_PRESET_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv264Profile"]}
          label={<FormattedMessage id="Nvidia264VideoCodec.nv264Profile" defaultMessage="Profile" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_264_PROFILE_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv264RateControl"]}
          label={<FormattedMessage id="Nvidia264VideoCodec.nv264RateControl" defaultMessage="Rate control" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NV_264_RATE_CONTROL_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <InputNumberField
          disabled={disabled}
          name={[...prefix, "nv264RCLookahead"]}
          label={<FormattedMessage id="Nvidia264VideoCodec.nv264RCLookahead" defaultMessage="Rate control lookahead" />}
          rules={[required]}
          min={0}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv264SelectGPU"]}
          label={<FormattedMessage id="Nvidia264VideoCodec.nv264SelectGPU" defaultMessage="Select GPU" />}
          rules={[required]}
          disabled={disabled}
        >
          <Select options={NVIDIA_HW_ENCODERS_SELECTOR} />
        </Item>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Item
          name={[...prefix, "nv264StrictGOP"]}
          valuePropName="checked"
          label={<FormattedMessage id="Nvidia264VideoCodec.nv264StrictGOP" defaultMessage="Strict GoP" />}
        >
          <Switch
            disabled={disabled}
            checkedChildren={formatMessage(globalTranslations.on)}
            unCheckedChildren={formatMessage(globalTranslations.off)}
          />
        </Item>
      </Col>
    </>
  );
}

Nvidia264VideoCodec.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disabled: PropTypes.bool.isRequired,
};
