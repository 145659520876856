import { useEffect } from "react";
import { useParams } from "react-router-dom";

import MQTTService from "../services/mqtt";

import useNodeVersion from "./useNodeVersion";

import { MESSAGE_TYPE } from "../lib/utils/constants";

function useNodePlayoutData() {
  const { cwid, id } = useParams();
  const { isMinV5 } = useNodeVersion();

  useEffect(() => {
    if (!isMinV5) {
      return null;
    }

    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.REQUEST_PLAYOUT_DATA,
        channelId: id,
      },
    });

    const liveDataInterval = setInterval(() => {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.REQUEST_PLAYOUT_DATA,
          channelId: id,
        },
      });
    }, 290000);

    return () => {
      if (isMinV5) {
        clearInterval(liveDataInterval);
      }
    };
  }, [cwid, id, isMinV5]);
}

export default useNodePlayoutData;
