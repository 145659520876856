import React from "react";
import { Form, Input } from "antd";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { required, max1024characters, urlValidation } from "../../../../../lib/utils/formValidation";

import InputNumberField from "../../../../Fields/InputNumberField";
import InputTextField from "../../../../Fields/InputTextField";

const { Item } = Form;

export default function InputHLS({ prefix, disabled }) {
  return (
    <>
      <InputNumberField name={[...prefix, "inputId"]} disabled={disabled} hidden />
      <InputTextField name={[...prefix, "inputName"]} disabled={disabled} hidden />
      <Item
        name={[...prefix, "url"]}
        label={<FormattedMessage id="InputHLS.url" defaultMessage="Url" />}
        rules={[required, max1024characters, urlValidation]}
      >
        <Input disabled={disabled} />
      </Item>
    </>
  );
}

InputHLS.propTypes = {
  disabled: PropTypes.bool,
  prefix: PropTypes.arrayOf(PropTypes.string).isRequired,
};

InputHLS.defaultProps = {
  disabled: null,
};
