import React, { useContext, useState } from "react";
import { Card, Col, Modal, Button, Row, Form, Switch } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FormV5Context } from "../../NodeChannelFormV5";

import InputNumberField from "../../../Fields/InputNumberField";
import InputTextField from "../../../Fields/InputTextField";

import { required, urlValidation } from "../../../../lib/utils/formValidation";
import globalTranslations from "../../../../lib/translations";

import useNodeChannelConfig from "../../../../hooks/useNodeChannelConfig";

import AdvancedSettings from "../../../AdvancedSettings";
import PlaylistSourceElement from "./PlaylistSourceElement";
import { actions as NODE_PLAYOUT_PLAYLIST_ACTIONS } from "../../../../ducks/nodePlayoutPlaylist";

const { Item } = Form;

export default function PlaylistSource({ detailView, handleFinish }) {
  const { formatMessage } = useIntl();
  const form = useContext(FormV5Context);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { channelConfig } = useNodeChannelConfig();

  const { getFieldValue } = form;
  const [openModal, setOpenModal] = useState(false);

  const handleCancelInput = () => {
    setOpenModal(false);
  };

  const handleOKInput = () => {
    const formData = getFieldValue("playlistSource");
    handleFinish({ ...channelConfig, playlistSource: formData });
    dispatch(NODE_PLAYOUT_PLAYLIST_ACTIONS.CLEAR_PLAYOUT_PLAYLIST({ channelId: +id }));
    setOpenModal(false);
  };

  return (
    <Col span={24}>
      <Card title={<FormattedMessage id="PlaylistSource.playlistSource" defaultMessage="Playlist source" />}>
        <PlaylistSourceElement
          handleClick={() => setOpenModal(true)}
          label={<FormattedMessage id="PlaylistSource.source" defaultMessage="Source" />}
          incomplete={channelConfig?.playlistSource?.incomplete}
        />
      </Card>
      <StyledModal
        title={<FormattedMessage id="PlaylistSource.playlistSource" defaultMessage="Playlist source" />}
        open={openModal}
        zIndex={100}
        centered
        destroyOnClose
        onCancel={handleCancelInput}
        footer={
          detailView ? null : (
            <>
              <Button key="back" onClick={handleCancelInput}>
                <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
              </Button>
              <Button key="ok" type="primary" onClick={handleOKInput} icon={<SaveOutlined />}>
                <span>
                  <FormattedMessage id="general.save" defaultMessage="Save" />
                </span>
              </Button>
            </>
          )
        }
      >
        <Card>
          <Row gutter={24}>
            <Col span={24}>
              <InputTextField
                name={["playlistSource", "url"]}
                disabled={detailView}
                label={<FormattedMessage id="playlistSource.url" defaultMessage="Url" />}
                rules={[required, urlValidation]}
              />
            </Col>
          </Row>
          <AdvancedSettings>
            <Row gutter={24}>
              <InputTextField name={["playlistSource", "type"]} disabled={detailView} hidden />
              <Col span={8}>
                <InputNumberField
                  disabled={detailView}
                  name={["playlistSource", "daysAhead"]}
                  label={<FormattedMessage id="playlistSource.daysAhead" defaultMessage="Days ahead [d]" />}
                  rules={[required]}
                  min={1}
                />
              </Col>
              <Col span={8}>
                <InputNumberField
                  disabled={detailView}
                  name={["playlistSource", "pingInterval"]}
                  label={<FormattedMessage id="playlistSource.pingInterval" defaultMessage="Ping interval [s]" />}
                  rules={[required]}
                  min={1}
                />
              </Col>
              <Col span={8}>
                <InputNumberField
                  disabled={detailView}
                  name={["playlistSource", "reconnectTimeout"]}
                  label={
                    <FormattedMessage id="playlistSource.reconnectTimeout" defaultMessage="Reconnect timeout [s]" />
                  }
                  rules={[required]}
                  min={1}
                />
              </Col>
              <Col span={8}>
                <InputNumberField
                  disabled={detailView}
                  name={["playlistSource", "refreshInterval"]}
                  label={
                    <FormattedMessage id="playlistSource.refreshInterval" defaultMessage="Refresh interval [min]" />
                  }
                  rules={[required]}
                  min={1}
                />
              </Col>
              <Col span={8}>
                <InputNumberField
                  disabled={detailView}
                  name={["playlistSource", "refreshIntervalOnErrors"]}
                  label={
                    <FormattedMessage
                      id="playlistSource.refreshIntervalOnErrors"
                      defaultMessage="Refresh interval on errors [min]"
                    />
                  }
                  rules={[required]}
                  min={1}
                />
              </Col>
              <Col span={8}>
                <Item
                  name={["playlistSource", "sendInfoToApi"]}
                  label={<FormattedMessage id="playlistSource.sendInfoToApi" defaultMessage="Send info to API" />}
                  valuePropName="checked"
                  rules={[required]}
                >
                  <Switch
                    checkedChildren={formatMessage(globalTranslations.enabled)}
                    unCheckedChildren={formatMessage(globalTranslations.disabled)}
                    disabled={detailView}
                  />
                </Item>
              </Col>
            </Row>
          </AdvancedSettings>
        </Card>
      </StyledModal>
    </Col>
  );
}

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

PlaylistSource.propTypes = {
  detailView: PropTypes.bool.isRequired,
  handleFinish: PropTypes.func.isRequired,
};
