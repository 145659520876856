/* eslint-disable max-len */
import { defineMessages } from "react-intl";

export default defineMessages({
  // Additional URL outputs
  outFile: {
    id: "global.outFile",
    defaultMessage: "Output to file",
  },
  playout: {
    id: "global.playout",
    defaultMessage: "Playout",
  },
  transcoding: {
    id: "global.transcoding",
    defaultMessage: "Transcoding",
  },
  direct: {
    id: "global.direct",
    defaultMessage: "Direct",
  },
  preview: {
    id: "global.preview",
    defaultMessage: "Preview",
  },
  host: {
    id: "global.host",
    defaultMessage: "Host",
  },
  on: {
    id: "global.on",
    defaultMessage: "ON",
  },
  off: {
    id: "global.off",
    defaultMessage: "OFF",
  },
  addressIp: {
    id: "global.addressIp",
    defaultMessage: "IP Address",
  },
  ownIPAddress: {
    id: "global.ownIPAddress",
    defaultMessage: "Your IP Address",
  },
  autoIPON: {
    id: "global.autoIPON",
    defaultMessage: "Auto IP ON",
  },
  autoIPOFF: {
    id: "global.autoIPOFF",
    defaultMessage: "Auto IP OFF",
  },
  backupDisabled: {
    id: "global.backupDisabled",
    defaultMessage: "Backup disabled",
  },
  backupEnabled: {
    id: "global.backupEnabled",
    defaultMessage: "Backup enabled",
  },
  destinationPort: {
    id: "global.destinationPort.label",
    defaultMessage: "Destination Port",
  },
  enabled: {
    id: "general.enabled",
    defaultMessage: "Enabled",
  },
  disabled: {
    id: "general.disabled",
    defaultMessage: "Disabled",
  },
  sourceIP: {
    id: "global.sourceIP.label",
    defaultMessage: "Source IP",
  },
  sourcePort: {
    id: "global.sourcePort.label",
    defaultMessage: "Source Port",
  },
  connectTimeout: {
    id: "global.connectTimeout.label",
    defaultMessage: "Timeout (s)",
  },
  oheadbw: {
    id: "global.oheadbw.label",
    defaultMessage: "Overhead bandwidth (%)",
  },
  passphrase: {
    id: "global.passphrase.label",
    defaultMessage: "Passphrase",
  },
  advancedParameters: {
    id: "global.advancedParameters.label",
    defaultMessage: "Advanced parameters",
  },
  somethingWentWrong: {
    id: "global.somethingWentWrong",
    defaultMessage: "Upss... Something Went Wrong!",
  },
  yes: {
    id: "global.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "global.no",
    defaultMessage: "No",
  },
  required: {
    id: "global.required",
    defaultMessage: "Field is required",
  },
  edit: {
    id: "global.edit",
    defaultMessage: "Edit",
  },
  create: {
    id: "global.create",
    defaultMessage: "Create",
  },
  delete: {
    id: "global.delete",
    defaultMessage: "Delete",
  },
  save: {
    id: "global.save",
    defaultMessage: "Save",
  },
  defaultError: {
    id: "global.defaultError",
    defaultMessage: "Connection error",
  },
  platformConnectionError: {
    id: "global.platformConnectionError",
    defaultMessage: "Platform connection error",
  },
  type: {
    id: "global.type",
    defaultMessage: "Type",
  },
  name: {
    id: "global.name",
    defaultMessage: "Name",
  },
  outputName: {
    id: "global.outputName",
    defaultMessage: "Output name",
  },
  close: {
    id: "global.close",
    defaultMessage: "Close",
  },
  width: {
    id: "global.width",
    defaultMessage: "Width",
  },
  height: {
    id: "global.height",
    defaultMessage: "Height",
  },
  valueOutOfRange: {
    id: "global.valueOutOfRange",
    defaultMessage: "Out of supported range",
  },

  // Playout translations
  ip: {
    id: "general.ip",
    defaultMessage: "IP",
  },
  interval: {
    id: "general.interval",
    defaultMessage: "Interval",
  },
  instanceNumber: {
    id: "general.instanceNumber",
    defaultMessage: "Instance number",
  },
  fpsNum: {
    id: "general.fpsNum",
    defaultMessage: "FPS Num",
  },
  fpsDen: {
    id: "general.fpsDen",
    defaultMessage: "FPS Den",
  },
  customPathFiles: {
    id: "general.customPathFiles",
    defaultMessage: "Custom path files",
  },
  useBackup: {
    id: "general.useBackup",
    defaultMessage: "Use backup",
  },
  customPathBackup: {
    id: "general.customPathBackup",
    defaultMessage: "Custom path backup",
  },
  customAdBreakFile: {
    id: "general.customAdBreakFile",
    defaultMessage: "Custom ad break file",
  },
  audioMatrixForAdBreak: {
    id: "general.audioMatrixForAdBreak",
    defaultMessage: "Audio matrix for ad break",
  },
  customEmergencyFile: {
    id: "general.customEmergencyFile",
    defaultMessage: "Custom emergency file",
  },
  audioMatrixForEmergency: {
    id: "general.audioMatrixForEmergency",
    defaultMessage: "Audio matrix for emergency",
  },
  mode: {
    id: "general.mode",
    defaultMessage: "Mode",
  },
  bufferFrames: {
    id: "general.bufferFrames",
    defaultMessage: "Buffer frames",
  },
  clonedFramesBeforeEmergency: {
    id: "general.clonedFramesBeforeEmergency",
    defaultMessage: "Cloned frames before emergency",
  },
  outputBufferLength: {
    id: "general.outputBufferLength",
    defaultMessage: "Output buffer length",
  },
  playlistEndingAlert: {
    id: "general.playlistEndingAlert",
    defaultMessage: "Playlist ending alert",
  },
  sourceAnalyzeDuration: {
    id: "general.sourceAnalyzeDuration",
    defaultMessage: "Source analyze duration",
  },
  sourceProbeSize: {
    id: "general.sourceProbeSize",
    defaultMessage: "Source probe size",
  },
  useHwDecoding: {
    id: "general.useHwDecoding",
    defaultMessage: "Use hw decoding",
  },
  customFontPath: {
    id: "general.customFontPath",
    defaultMessage: "Custom font path",
  },
  textEncoding: {
    id: "general.textEncoding",
    defaultMessage: "Text encoding",
  },
  forced: {
    id: "general.forced",
    defaultMessage: "Forced",
  },
  forcedCustomPath: {
    id: "general.forcedCustomPath",
    defaultMessage: "Forced custom path",
  },
  burningEnabled: {
    id: "general.burningEnabled",
    defaultMessage: "Burning enabled",
  },
  cea608Enabled: {
    id: "general.cea608Enabled",
    defaultMessage: "Cea608 enabled",
  },
  cea608Mode: {
    id: "general.cea608Mode",
    defaultMessage: "Cea608 mode",
  },
  apiMode: {
    id: "general.apiMode",
    defaultMessage: "Api mode",
  },
  commandMode: {
    id: "general.commandMode",
    defaultMessage: "Command mode",
  },
  omitCueIn: {
    id: "general.omitCueIn",
    defaultMessage: "Omit cue in",
  },
  repeat: {
    id: "general.repeat",
    defaultMessage: "Repeat",
  },
  bufferFramesStart: {
    id: "general.bufferFramesStart",
    defaultMessage: "Buffer frames start",
  },
  fileCheckInterval: {
    id: "general.fileCheckInterval",
    defaultMessage: "File check interval",
  },
  fileNameSuffix: {
    id: "general.fileNameSuffix",
    defaultMessage: "File name suffix",
  },
  getFrameTimeout: {
    id: "general.getFrameTimeout",
    defaultMessage: "Get frame timeout",
  },
  maxInterstreamDistance: {
    id: "general.maxInterstreamDistance",
    defaultMessage: "Max interstream distance",
  },
  maxRestartTime: {
    id: "general.maxRestartTime",
    defaultMessage: "Max restart time",
  },
  tcOutRelaxedLimitFrames: {
    id: "general.tcOutRelaxedLimitFrames",
    defaultMessage: "Tc out relaxed limit frames",
  },
  openTimeout: {
    id: "general.openTimeout",
    defaultMessage: "Open timeout",
  },
  reinitDelay: {
    id: "general.reinitDelay",
    defaultMessage: "Reinit delay",
  },
  freezeInverval: {
    id: "general.freezeInverval",
    defaultMessage: "Freeze inverval",
  },
  freezeSensitivity: {
    id: "general.freezeSensitivity",
    defaultMessage: "Freeze sensitivity",
  },
  port: {
    id: "general.port",
    defaultMessage: "Port",
  },
  silenceInterval: {
    id: "general.silenceInterval",
    defaultMessage: "Silence interval",
  },
  silenceSensitivity: {
    id: "general.silenceSensitivity",
    defaultMessage: "Silence sensitivity",
  },
  customPath: {
    id: "general.customPath",
    defaultMessage: "Custom path",
  },
  stopTime: {
    id: "general.stopTime",
    defaultMessage: "Stop time",
  },
  addClearToAllItems: {
    id: "general.addClearToAllItems",
    defaultMessage: "Add clear to all items",
  },
  casparCustomPath: {
    id: "general.casparCustomPath",
    defaultMessage: "Caspar custom path",
  },
  casparStopTime: {
    id: "general.casparStopTime",
    defaultMessage: "Caspar stop time",
  },
  casparAddClearToAllItems: {
    id: "general.casparAddClearToAllItems",
    defaultMessage: "Caspar add clear to all items",
  },
  casparIp: {
    id: "general.casparIp",
    defaultMessage: "Caspar ip",
  },
  casparNdiId: {
    id: "general.casparNdiId",
    defaultMessage: "Caspar ndi id",
  },
  casparChannel: {
    id: "general.casparChannel",
    defaultMessage: "Caspar channel",
  },
  casparPort: {
    id: "general.casparPort",
    defaultMessage: "Caspar port",
  },
  Mpeg2video: {
    id: "general.Mpeg2video",
    defaultMessage: "MPEG-2 Video",
  },
  OpenH264: {
    id: "general.OpenH264",
    defaultMessage: "OPENH264",
  },
  X264: {
    id: "general.X264",
    defaultMessage: "H.264",
  },
  Nv264: {
    id: "general.Nv264",
    defaultMessage: "Nv264",
  },
  Nv265: {
    id: "general.Nv265",
    defaultMessage: "Nv265",
  },

  // Error codes
  400: {
    id: "global.400error",
    defaultMessage: "Bad request",
  },
  401: {
    id: "global.401error",
    defaultMessage: "User doesn't exist",
  },
  402: {
    id: "global.402error",
    defaultMessage: "Incorrect username and/or password",
  },
  403: {
    id: "global.403error",
    defaultMessage: "Your token has expired or you do not have access to this resource",
  },
  404: {
    id: "global.404error",
    defaultMessage: "Not Found",
  },
  405: {
    id: "global.405error",
    defaultMessage: "New password need to be different than old password",
  },
  406: {
    id: "global.406error",
    defaultMessage:
      "Sorry but you have not passed the Turnstile token validation test - please try again or contact our support team at hello@quickstream.tech",
  },
  407: {
    id: "global.407error",
    defaultMessage: "New username needs to be different than old username",
  },
  408: {
    id: "global.408error",
    defaultMessage: "Request Time-Out",
  },
  409: {
    id: "global.409error",
    defaultMessage: "User with this username already exists",
  },
  410: {
    id: "global.410error",
    defaultMessage: "Only admin can add another admin account",
  },
  // 411: {
  //   id: "global.411error",
  //   defaultMessage: "Only admin can add another admin account",
  // },
  412: {
    id: "global.412error",
    defaultMessage: "Email verification error - please use your business non-free email address",
  },
  413: {
    id: "global.413error",
    defaultMessage: "Admin rights required",
  },
  414: {
    id: "global.414error",
    defaultMessage: "License does not exist",
  },
  415: {
    id: "global.415error",
    defaultMessage: "User was deactivated - please contact with admin",
  },
  420: {
    id: "global.420error",
    defaultMessage: "This channel ID does not exist",
  },
  422: {
    id: "global.422error",
    defaultMessage: "Wrong data provided - input validation error",
  },
  429: {
    id: "global.429error",
    defaultMessage: "Too many requests from this IP, please try again after 10s",
  },
  452: {
    id: "global.452error",
    defaultMessage: "General exception ",
  },
  453: {
    id: "global.453error",
    defaultMessage: "General database exception",
  },
  454: {
    id: "global.454error",
    defaultMessage: "Database error while inserting ",
  },
  455: {
    id: "global.455error",
    defaultMessage: "Database error while updating",
  },
  456: {
    id: "global.456error",
    defaultMessage: "Database error while querying ",
  },
  457: {
    id: "global.457error",
    defaultMessage: "Database error while deleting",
  },
  458: {
    id: "global.458error",
    defaultMessage: "Data integrity exception",
  },
  459: {
    id: "global.459error",
    defaultMessage: "Incorrect input value",
  },
  460: {
    id: "global.460error",
    defaultMessage: "Invalid hardware ID",
  },
  461: {
    id: "global.461error",
    defaultMessage: "Invalid id engine",
  },
  462: {
    id: "global.462error",
    defaultMessage: "Invalid license",
  },
  463: {
    id: "global.463error",
    defaultMessage: "Invalid permissions",
  },
  464: {
    id: "global.464error",
    defaultMessage: "Channel does not exist",
  },
  465: {
    id: "global.465error",
    defaultMessage: "User for this permission does not exist",
  },
  466: {
    id: "global.466error",
    defaultMessage: "Cloud permission error",
  },
  467: {
    id: "global.467error",
    defaultMessage: "QS permission - data can not be fetch",
  },
  468: {
    id: "global.468error",
    defaultMessage: "Node does not exist in cloud",
  },
  469: {
    id: "global.469error",
    defaultMessage: "Unable to create a permanent license for a virtual machine",
  },
  471: {
    id: "global.471error",
    defaultMessage: "License does not exist",
  },
  472: {
    id: "global.472error",
    defaultMessage: "This license ID already exists in database",
  },
  473: {
    id: "global.473error",
    defaultMessage: "This channel has Shares connected - please remove it first",
  },
  474: {
    id: "global.474error",
    defaultMessage: "Node is already connected to Quickstream Cloud",
  },
  475: {
    id: "global.475error",
    defaultMessage: "The old password you have entered is incorrect",
  },
  476: {
    id: "global.476error",
    defaultMessage: "This email address is already taken",
  },
  477: {
    id: "global.477error",
    defaultMessage:
      // eslint-disable-next-line max-len
      "This node has been given access in the cloud for other users, please remove this access manually before disconnecting node from cloud",
  },
  478: {
    id: "global.478error",
    defaultMessage: "Shared channel does not exist or was removed by owner",
  },
  479: {
    id: "global.479error",
    defaultMessage: "Your activation link has expired. Please fill out the registration form again.",
  },
  480: {
    id: "global.480error",
    defaultMessage: "This share is connected - please remove it from chanel input first",
  },
  481: {
    id: "global.481error",
    defaultMessage: "Invalid cloud permissions",
  },
  482: {
    id: "global.482error",
    defaultMessage: "Incorrect url link or it expired",
  },
  483: {
    id: "global.483error",
    defaultMessage: "Only one license edition per day possible",
  },
  484: {
    id: "global.484error",
    defaultMessage: "Unable edit permanent license",
  },
  485: {
    id: "global.485error",
    defaultMessage: "Unable to create permanent license for playout license with more than 1 channel",
  },
  486: {
    id: "global.486error",
    defaultMessage: "Wrong hardware id for playout license",
  },
  487: {
    id: "global.487error",
    defaultMessage: "Invalid hardware id for this license type",
  },
  488: {
    id: "global.488error",
    defaultMessage: "Hardware ID required for permanent licenses",
  },
  489: {
    id: "global.489error",
    defaultMessage: "Organization already exist",
  },
  490: {
    id: "global.490error",
    defaultMessage: "Organization does not exist",
  },
  491: {
    id: "global.491error",
    defaultMessage: "You can not edit this organization",
  },
  492: {
    id: "global.492error",
    defaultMessage: "Not allowed image type",
  },
  493: {
    id: "global.493error",
    defaultMessage: "Upload logotype error",
  },
  494: {
    id: "global.494error",
    defaultMessage: "Delete logo error",
  },
  495: {
    id: "global.495error",
    defaultMessage: "Create new organization - limit reached",
  },
  496: {
    id: "global.496error",
    defaultMessage: "This hub channel is connected to node - please remove it from node before transfer",
  },
  497: {
    id: "global.497error",
    defaultMessage: "The instance cannot be created - instance type limit",
  },
  498: {
    id: "global.498error",
    defaultMessage: "Please wait few moments (1-2 min) before next action - instance is still processing last command",
  },
  499: {
    id: "global.499error",
    defaultMessage: "Two factor authentication required",
  },
  500: {
    id: "global.500error",
    defaultMessage: "Internal Server Error",
  },
  501: {
    id: "global.501error",
    defaultMessage: "Inactive cloud node license - new instance cannot be created",
  },
  502: {
    id: "global.502error",
    defaultMessage: "Billing session error",
  },
});
