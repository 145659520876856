import React from "react";
import { Card, Table, Tooltip, Tag, Descriptions } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  selectors as NODE_PLAYOUT_PLAYLIST_SELECTORS,
  MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR,
} from "../../ducks/nodePlayoutPlaylist";

import NodePlayoutPlaylistsStatus from "./NodePlayoutPlaylistsStatus";

import themeColors from "../../lib/style/theme";
import { NODE_PLAYOUT_PLAYLIST_TYPE } from "../../lib/utils/constants";

function ExpandedRow(record) {
  return <pre>{JSON.stringify({ cgEvents: record?.cgEvents }, null, 2)}</pre>;
}

ExpandedRow.propTypes = {
  record: PropTypes.shape({
    cgEvents: PropTypes.string,
  }).isRequired,
};

const columns = [
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.date" defaultMessage="DATE" />,
    dataIndex: "time",
    key: "time",
    render: (time, record) => (
      <Tooltip placement="top" title={record?.date}>
        <span>{time}</span>
      </Tooltip>
    ),
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.name" defaultMessage="NAME" />,
    dataIndex: "clipName",
    key: "clipName",
    render: (clipName, record) => (
      <span>
        <StyledSpan>{record?.clipName}</StyledSpan>
        {record?.fileName ? ` (${record.fileName})` : ""}
      </span>
    ),
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.type" defaultMessage="TYPE" />,
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (type) => {
      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.FILE) {
        return (
          <Tag bordered={false} color={themeColors.defaultBlueColor}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.AD_BREAK) {
        return (
          <Tag bordered={false} color={themeColors.orange}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.LIVE) {
        return (
          <Tag bordered={false} color={themeColors.red}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.PLACEHOLDER) {
        return (
          <Tag bordered={false} color={themeColors.maritime}>
            {type}
          </Tag>
        );
      }

      return <Tag bordered={false}>{type}</Tag>;
    },
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.statusUppercase" defaultMessage="STATUS" />,
    dataIndex: "itemStatus",
    key: "itemStatus",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.extAudioStatus" defaultMessage="EXT. AUDIO" />,
    dataIndex: "extAudioStatus",
    key: "extAudioStatus",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.duration" defaultMessage="DURATION" />,
    dataIndex: "formattedDuration",
    key: "formattedDuration",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.tcIn" defaultMessage="TC IN" />,
    dataIndex: "formattedTcIn",
    key: "formattedTcIn",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.tcOut" defaultMessage="TC OUT" />,
    dataIndex: "formattedTcOut",
    key: "formattedTcOut",
    align: "center",
  },
];

const NodePlayoutPlaylists = () => {
  const { id } = useParams();
  const playoutPlaylist = useSelector((state) => MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR(state, +id));
  const apiChannel = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistApiChannel(state, +id));
  const apiConnected = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistApiConnected(state, +id)
  );
  const cgMode = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgMode(state, +id));
  const cgControl = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgControl(state, +id));
  const cgSource = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgSource(state, +id));
  const cgControlStatus = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgControlStatus(state, +id)
  );
  const cgSourceStatus = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgSourceStatus(state, +id)
  );
  const elemPath0 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath0(state, +id));
  const elemStatus0 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus0(state, +id));
  const elemPath20 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath20(state, +id));
  const elemStatus20 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus20(state, +id)
  );
  const elemPath21 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath21(state, +id));
  const elemStatus21 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus21(state, +id)
  );
  const elemPath22 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath22(state, +id));
  const elemStatus22 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus22(state, +id)
  );

  const items = [
    {
      key: "apiChannel",
      label: <FormattedMessage id="NodePlayoutPlaylists.apiChannel" defaultMessage="Api channel" />,
      children: (
        <div>
          <div>{apiChannel}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={apiConnected} />
          </div>
        </div>
      ),
    },
    {
      key: "cgMode",
      label: <FormattedMessage id="NodePlayoutPlaylists.cg" defaultMessage="CG" />,
      children: (
        <div>
          <div>{cgMode}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.control" defaultMessage="Control" />: {cgControl}
            {", "}
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={cgControlStatus} />
          </div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.source" defaultMessage="Source" />: {cgSource}
            {", "}
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={cgSourceStatus} />
          </div>
        </div>
      ),
    },
    {
      key: "elemPath0",
      label: <FormattedMessage id="NodePlayoutPlaylists.switcher" defaultMessage="Switcher" />,
      children: (
        <div>
          <div>{elemPath0}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus0} />
          </div>
        </div>
      ),
    },
    {
      key: "elemPath20",
      label: <FormattedMessage id="NodePlayoutPlaylists.head1" defaultMessage="Head 1" />,
      children: (
        <div>
          <div>{elemPath20}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus20} />
          </div>
        </div>
      ),
    },
    {
      key: "elemPath21",
      label: <FormattedMessage id="NodePlayoutPlaylists.head2" defaultMessage="Head 2" />,
      children: (
        <div>
          <div>{elemPath21}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus21} />
          </div>
        </div>
      ),
    },
    {
      key: "live",
      label: <FormattedMessage id="NodePlayoutPlaylists.live" defaultMessage="Live" />,
      children: (
        <div>
          <div>{elemPath22}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus22} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Card>
      <Descriptions items={items} labelStyle={{ color: themeColors.maritime }} />
      <Table
        pagination={false}
        dataSource={playoutPlaylist}
        columns={columns}
        expandable={{
          expandedRowRender: ExpandedRow,
          rowExpandable: (record) => !!record?.cgEvents,
          expandRowByClick: true,
        }}
        rowKey={(record) => record?.guid}
      />
    </Card>
  );
};

const StyledSpan = styled.span`
  color: ${themeColors.orange};
`;

export default NodePlayoutPlaylists;
