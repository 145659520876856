import React, { useState, useContext } from "react";
import { Card, Row, Button, Modal, Col, Empty, Spin } from "antd";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import { PlusOutlined, EditOutlined, SearchOutlined, SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import parseConnectionMethodName from "../../../../lib/utils/parseConnectionMethodName";
import inputURLDefaultValues from "../../../../lib/utils/defaultValues/inputURLDefaultValues";

import {
  QUICKSTREAM_METHOD_V2,
  // INPUT_DECKLINK_TYPE,
  INPUT_URL_TYPE,
  // SELECT_DEFAULT_AUTO,
  INPUT_WEBCAM_TYPE,
  // INPUT_TYPES,
  INPUT_STREAM_TYPE,
  SELECT_DEFAULT_AUTO,
} from "../../../../lib/utils/constants";

import useNodeOS from "../../../../hooks/useNodeOS";

import InputStreamSection from "../InputStreamSection";
import ProbeDrawerV5 from "../../../ProbeDrawerV5/ProbeDrawerV5";
import InputStreamSourceElement from "../../../InputStreamSourceElement";
// import DecklinkElement from "../../DecklinkElement";
// import WebcamElement from "../../WebcamElement";
import SourceSwitch from "../../../SourceSwitch";
// import InputNDIElement from "../../InputNDIElement";

import { FormV5Context } from "../../NodeChannelFormV5";

const translations = defineMessages({
  main: {
    id: "InputSection.main",
    defaultMessage: "Main",
  },
  backup: {
    id: "InputSection.backup",
    defaultMessage: "Backup",
  },
});

function InputSection({
  channelConfig,
  detailView,
  handleFinish,
  id,
  requestedStatusText,
  sharedChannelList,
  stundAddress,
  playingSource,
  inputsStatus,
}) {
  const { formatMessage } = useIntl();
  const { getFieldValue, resetFields, validateFields, setFieldsValue } = useContext(FormV5Context);
  const [inputStreamModalVisible, setInputStreamModalVisible] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const { isLinux } = useNodeOS();

  const handleOKMainInput = async () => {
    try {
      await validateFields();
      const formInput = getFieldValue("input");

      const updatedFormData = { ...channelConfig, input: formInput };

      handleFinish(updatedFormData);
      setInputStreamModalVisible(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Validation Error", error);
    }
  };

  const handleCancelMainInput = () => {
    resetFields();
    setInputStreamModalVisible(false);
  };

  const handleShowEditModal = () => {
    if (!channelConfig?.input?.mainConnection) {
      const inputFormData = inputURLDefaultValues({
        input: channelConfig?.input,
        stundAddress,
        sharedChannelList,
      });

      setFieldsValue({
        ...channelConfig,
        input: {
          ...inputFormData,
          ...(isLinux
            ? { mainConnection: { ...inputFormData?.mainConnection, interfaceIp: SELECT_DEFAULT_AUTO.value } }
            : {}),
        },
      });
    }

    setInputStreamModalVisible(true);
  };

  const handleShowProbe = async () => {
    setShowDrawer(true);
  };

  const isInput = channelConfig?.input?.mainConnection;

  const isBackup = !!channelConfig?.input?.switchInputBackup;

  const isMainQSMethod = !!(channelConfig?.input?.mainConnection?.type === QUICKSTREAM_METHOD_V2.inQSDirect.value);

  const isMainInputURLType = channelConfig?.input?.type === INPUT_URL_TYPE.value;
  const isMainInputWebcamType = channelConfig?.input?.type === INPUT_WEBCAM_TYPE.value;

  return (
    <Card
      title={
        <Row justify="space-between">
          <FormattedMessage id="InputSection.inputStream" defaultMessage="Input stream" />
          {isInput && !detailView && (
            <Col>
              <StyledButton
                disabled={isMainQSMethod || isMainInputWebcamType}
                icon={<SearchOutlined />}
                onClick={handleShowProbe}
                type="primary"
              >
                <span>
                  <FormattedMessage id="InputSection.probeStream" defaultMessage="Probe stream" />
                </span>
              </StyledButton>
              <Button type="primary" onClick={handleShowEditModal} icon={<EditOutlined />}>
                <span>
                  <FormattedMessage id="general.edit" defaultMessage="Edit" />
                </span>
              </Button>
            </Col>
          )}
        </Row>
      }
      extra={
        detailView &&
        isBackup && (
          <SourceSwitch
            channelId={id}
            playingSource={playingSource}
            requestedStatusText={requestedStatusText}
            inputsStatus={inputsStatus}
            type={channelConfig?.type}
          />
        )
      }
    >
      <Row gutter={24} justify="center">
        <ProbeDrawerV5
          type={channelConfig?.type}
          getFieldValue={getFieldValue}
          isBackup={!!isBackup}
          isMainQSMethod={isMainQSMethod}
          setShowDrawer={setShowDrawer}
          showDrawer={showDrawer}
          validateFields={validateFields}
        />
        {isInput && (
          <Col md={24} lg={12}>
            <StyledSpin
              indicator={<> </>}
              spinning={playingSource !== INPUT_STREAM_TYPE.main && detailView}
              tip={<FormattedMessage id="InputSection.inactive" defaultMessage="Inactive" />}
              onClick={() => setInputStreamModalVisible(true)}
            >
              {isMainInputURLType && (
                <InputStreamSourceElement
                  connectionType={channelConfig?.input?.mainConnection?.type}
                  cloudId={channelConfig?.input?.mainConnection?.cloudId}
                  destinationAddress={channelConfig?.input?.mainConnection?.destinationAddress}
                  port={
                    channelConfig?.input?.mainConnection?.destinationPort ||
                    channelConfig?.input?.mainConnection?.localPort
                  }
                  handleClick={handleShowEditModal}
                  interfaceIp={channelConfig?.input?.mainConnection?.interfaceIp}
                  label={formatMessage(translations.main)}
                  title={
                    channelConfig?.input?.mainConnection?.type &&
                    parseConnectionMethodName(channelConfig?.input?.mainConnection?.type)
                  }
                  incomplete={channelConfig?.input?.mainConnection?.incomplete}
                />
              )}
            </StyledSpin>
          </Col>
        )}
        {channelConfig?.input?.backupConnection && (
          <Col md={24} lg={12}>
            <StyledSpin
              indicator={<> </>}
              spinning={playingSource !== INPUT_STREAM_TYPE.backup && detailView}
              tip={<FormattedMessage id="InputSection.inactive" defaultMessage="Inactive" />}
              onClick={() => setInputStreamModalVisible(true)}
            >
              <InputStreamSourceElement
                connectionType={channelConfig?.input?.backupConnection?.type}
                destinationAddress={channelConfig?.input?.backupConnection?.destinationAddress}
                port={
                  channelConfig?.input?.backupConnection?.destinationPort ||
                  channelConfig?.input?.backupConnection?.localPort
                }
                handleClick={() => setInputStreamModalVisible(true)}
                interfaceIp={channelConfig?.input?.backupConnection?.interfaceIp}
                label={formatMessage(translations.backup)}
                title={
                  channelConfig?.input?.backupConnection?.type &&
                  parseConnectionMethodName(channelConfig?.input?.backupConnection?.type)
                }
                incomplete={channelConfig?.input?.backupConnection?.incomplete}
              />
            </StyledSpin>
          </Col>
        )}
        {!isInput && !detailView && (
          <Button type="dashed" style={{ width: "100%", margin: "10px" }} onClick={handleShowEditModal}>
            <PlusOutlined /> <FormattedMessage id="InputSection.addInput" defaultMessage="Add input" />
          </Button>
        )}

        {!isInput && detailView && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Row>
      {inputStreamModalVisible && (
        <StyledModal
          zIndex={100}
          centered
          title={<FormattedMessage id="InputSection.inputStreamForm" defaultMessage="Input Stream Form" />}
          open={inputStreamModalVisible}
          onCancel={handleCancelMainInput}
          footer={
            detailView ? null : (
              <>
                <Button key="back" onClick={handleCancelMainInput}>
                  <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
                </Button>
                <Button key="ok" type="primary" onClick={handleOKMainInput} icon={<SaveOutlined />}>
                  <span>
                    <FormattedMessage id="general.save" defaultMessage="Save" />
                  </span>
                </Button>
              </>
            )
          }
        >
          <InputStreamSection
            isInput={!!channelConfig.input}
            channelList={sharedChannelList}
            disabled={detailView}
            initialValue={channelConfig?.input}
            sharedChannelList={sharedChannelList}
            stundAddress={stundAddress}
          />
        </StyledModal>
      )}
    </Card>
  );
}

const StyledButton = styled(Button)`
  margin: 0 5px;
`;

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledSpin = styled(Spin)`
  .ant-spin-text {
    top: 30% !important;
    font-weight: bold;
  }
`;

InputSection.propTypes = {
  channelConfig: PropTypes.object,
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  id: PropTypes.string,
  requestedStatusText: PropTypes.string,
  sharedChannelList: PropTypes.array,
  stundAddress: PropTypes.string,
  playingSource: PropTypes.string.isRequired,
  inputsStatus: PropTypes.arrayOf(
    PropTypes.shape({
      connectionInfo: PropTypes.string.isRequired,
      errorCode: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      permissionId: PropTypes.number.isRequired,
      threadStatus: PropTypes.number.isRequired,
      threadStatusText: PropTypes.string.isRequired,
    })
  ).isRequired,
};

InputSection.defaultProps = {
  channelConfig: null,
  detailView: null,
  id: null,
  requestedStatusText: null,
  sharedChannelList: null,
  stundAddress: null,
};

export default InputSection;
