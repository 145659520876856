import React from "react";
import { Badge } from "antd";

export default function NodePlayoutPlaylistsStatus({ status }) {
  const parsedStatus = status.toUpperCase();

  if (status === "initialized") {
    return <Badge text={parsedStatus} color="blue" />;
  }

  if (status === "playing") {
    return <Badge status="processing" text={parsedStatus} color="green" />;
  }

  if (status === "paused") {
    return <Badge status="warning" text={parsedStatus} />;
  }

  if (status === "stopped") {
    return <Badge status="error" text={parsedStatus} />;
  }

  if (status === "connected") {
    return <Badge status="processing" text={parsedStatus} color="green" />;
  }

  return <Badge status="default" text={parsedStatus} />;
}
