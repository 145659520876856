import React, { useContext } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Form, Col, Switch, Row } from "antd";
import PropTypes from "prop-types";

import Nvidia264VideoCodec from "./Nvidia264VideoCodec";
import Nvidia265VideoCodec from "./Nvidia265VideoCodec";
import VideoCodecBitrateField from "../VideoCodecBitrateField";
import VideoCodecGOPLengthField from "../VideoCodecGOPLengthField";
import InputNumberField from "../Fields/InputNumberField";
import InterlacedField from "../InterlacedField";
import AdvancedSettings from "../AdvancedSettings";
// eslint-disable-next-line max-len
import GlobalVideoCodecFormItem from "../NodeChannelFormV5/EngineTranscoding/GlobalCodecSection/GlobalCodecVideo/GlobalVideoCodecFormItem";

import { FormV5Context } from "../NodeChannelFormV5/NodeChannelFormV5";

import globalTranslations from "../../lib/translations";
import { required } from "../../lib/utils/formValidation";
import { TRANSCODING_OPEN_H264_PIX_FORMAT } from "../../lib/utils/constants";
import useNodeChannelConfig from "../../hooks/useNodeChannelConfig";

const { Item } = Form;

const translations = defineMessages({
  bitrate: {
    id: "NvidiaVideoCodecs.bitrate",
    defaultMessage: "Bitrate [kbps]",
  },
  gopStructure: {
    id: "NvidiaVideoCodecs.gopStructure",
    defaultMessage: "B-Frames [frames]",
  },
});

export default function NvidiaVideoCodecs({ prefix, disabled, disableInterlaced }) {
  const form = useContext(FormV5Context);
  const { formatMessage } = useIntl();
  const videoStreamPrefix = prefix.slice(0, -1);

  const { isEnginePlayout, isEngineTranscoding } = useNodeChannelConfig();

  const videoStreamType = Form.useWatch([...prefix, "type"], form);
  const convertValue = Form.useWatch([...videoStreamPrefix, "convert"], form);
  const isSwitchConvert = isEnginePlayout ? true : convertValue;
  const isNvidia264Type = videoStreamType === "Nv264";
  const isNvidia265Type = videoStreamType === "Nv265";

  const { getFieldValue, setFieldValue } = form;

  const changeGOP = Form.useWatch([...prefix, "changeGOP"], form) || false;
  const gValue = Form.useWatch([...prefix, "g"], form);

  const handleSwitchChangeGOP = (value) => {
    const codec = getFieldValue(prefix);

    if (value) {
      const codecUpdate = {
        changeGOP: value,
        g: 25,
        bf: 0,
      };

      setFieldValue(prefix, { ...codec, ...codecUpdate });
    } else {
      delete codec.g;
      delete codec.bf;
      setFieldValue(prefix, { ...codec, changeGOP: value });
    }
  };

  const handleSwitchConvert = (value) => {
    const videoStream = getFieldValue(videoStreamPrefix);

    if (value) {
      const defaultVideoFormat = {
        resize: "pad",
        presetId: 2,
        width: 1920,
        height: 1080,
        pixFormat: "YUV420P",
        customSar: false,
        sarDen: 37,
        sarNum: 64,
      };

      setFieldValue([...videoStreamPrefix, "videoFormat"], defaultVideoFormat);
    } else {
      delete videoStream.videoFormat;
      setFieldValue(videoStreamPrefix, videoStream);
    }
  };

  return (
    <>
      <Col xs={24} sm={12}>
        <VideoCodecBitrateField
          disabled={disabled}
          name={[...prefix, "b"]}
          label={formatMessage(translations.bitrate)}
        />
      </Col>
      <AdvancedSettings>
        <Row gutter={12}>
          <Col span={24}>
            <Row gutter={12}>
              <Col xs={24} sm={12} md={6}>
                <Item
                  name={[...prefix, "changeGOP"]}
                  valuePropName="checked"
                  label={<FormattedMessage id="Nvidia264VideoCodec.changeGOP" defaultMessage="Custom GoP" />}
                >
                  <Switch
                    disabled={disabled}
                    onChange={handleSwitchChangeGOP}
                    defaultChecked={changeGOP}
                    checkedChildren={formatMessage(globalTranslations.on)}
                    unCheckedChildren={formatMessage(globalTranslations.off)}
                  />
                </Item>
              </Col>
              {changeGOP && (
                <>
                  <Col xs={24} sm={12} md={6}>
                    <VideoCodecGOPLengthField disabled={disabled} prefix={prefix} name={[...prefix, "g"]} form={form} />
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <InputNumberField
                      disabled={disabled}
                      name={[...prefix, "bf"]}
                      label={formatMessage(translations.gopStructure)}
                      rules={[required]}
                      min={0}
                      max={gValue}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col sm={24} md={12} style={disableInterlaced ? { visibility: "hidden", display: "none" } : null}>
            <InterlacedField prefix={prefix} disabled={disabled || disableInterlaced} />
          </Col>
          {isNvidia264Type && <Nvidia264VideoCodec disabled={disabled} prefix={prefix} />}
          {isNvidia265Type && <Nvidia265VideoCodec disabled={disabled} prefix={prefix} />}

          {isEngineTranscoding && (
            <Col span={6}>
              <Item
                name={[...videoStreamPrefix, "convert"]}
                valuePropName="checked"
                label={<FormattedMessage id="GeneralVideoCodec.convert" defaultMessage="Convert" />}
              >
                <Switch
                  disabled={disabled}
                  onChange={handleSwitchConvert}
                  defaultChecked={isSwitchConvert}
                  checkedChildren={formatMessage(globalTranslations.on)}
                  unCheckedChildren={formatMessage(globalTranslations.off)}
                />
              </Item>
            </Col>
          )}
          {isSwitchConvert && (
            <Col span={24}>
              <GlobalVideoCodecFormItem
                disabled={disabled}
                prefix={[...videoStreamPrefix, "videoFormat"]}
                isVideoFormat
                pixelFormat={TRANSCODING_OPEN_H264_PIX_FORMAT}
              />
            </Col>
          )}
        </Row>
      </AdvancedSettings>
    </>
  );
}

NvidiaVideoCodecs.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  disabled: PropTypes.bool.isRequired,
  disableInterlaced: PropTypes.bool.isRequired,
};
