import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import { createSelector } from "reselect";

export const SET_DEVICES_LIST = createAction("DEVICES/SET_DEVICES_LIST");
export const SET_LOADING_DEVICES_LIST = createAction("DEVICES/SET_LOADING_DEVICES_LIST");
export const CLEAR_DEVICES_LIST = createAction("DEVICES/CLEAR_DEVICES_LIST");

const defaultState = new Map({
  loading: null,
  decklinkInputs: [],
  decklinkOutputs: [],
  webcamAudioInputs: [],
  webcamVideoInputs: [],
});

const reducer = handleActions(
  {
    [SET_DEVICES_LIST]: (state, { payload }) => {
      return state.merge(payload);
    },
    [SET_LOADING_DEVICES_LIST]: (state, { payload }) => {
      return state.merge({ loading: payload });
    },
    [CLEAR_DEVICES_LIST]: () => {
      return defaultState;
    },
  },
  defaultState
);

export const selectors = {
  getDecklinkInputs: (state) => state.getIn(["nodeDevices", "decklinkInputs"]),
  getDecklinkOutputs: (state) => state.getIn(["nodeDevices", "decklinkOutputs"]),
  getWebcamAudioInputs: (state) => state.getIn(["nodeDevices", "webcamAudioInputs"]),
  getWebcamVideoInputs: (state) => state.getIn(["nodeDevices", "webcamVideoInputs"]),
  getEnvidiaHWEncoders: (state) => state.getIn(["nodeDevices", "hwEncoders", "nVidia"]),
  getLoadingDevices: (state) => state.getIn(["nodeDevices", "loading"]),
};

export const getEnvidiaHWEncodersSelector = createSelector([selectors.getEnvidiaHWEncoders], (hwEncoders) => {
  const defaultHwEncoder = [{ value: -1, label: "Automatic" }];

  if (!hwEncoders?.length) {
    return defaultHwEncoder;
  }

  const hwEncodersSelector = hwEncoders.map((encoder) => ({
    value: encoder?.num,
    label: encoder?.name,
  }));

  return [...defaultHwEncoder, ...hwEncodersSelector];
});

export default reducer;
