import React, { useState } from "react";
import { Col, Card, Row, Modal, Button, Empty } from "antd";
import styled from "styled-components";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { PlusOutlined, CaretDownOutlined, SaveOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import { PlatformAPI } from "../../lib/api";
import MQTTService from "../../services/mqtt";
import { selectors as NODE_SELECTORS } from "../../ducks/node";

import InputTextFiled from "../Fields/InputTextField";

import globalTranslations from "../../lib/translations";
import { max32characters, az09space, required } from "../../lib/utils/formValidation";
import parseConnectionMethodName from "../../lib/utils/parseConnectionMethodName";
import { OUT_UDP_DEFAULT } from "../../lib/utils/defaultValues/defaultNonMuxedOutput";
import themeColor from "../../lib/style/theme";
import { OUTPUTS_TYPE, NEW, MESSAGE_TYPE, QUICKSTREAM_METHOD_V2 } from "../../lib/utils/constants";
import { DEFAULT_MUXED_OUTPUT_OUTPUT } from "../../lib/utils/defaultValues/defaultMuxedOutput";
import { warningNotification, errorNotification } from "../../lib/utils/notification";

import StreamSourceElement from "../StreamSourceElement";
import OutputFormItem from "../NodeChannelFormV4/OutputFormItem";
import Muxer from "../NodeChannelFormV4/MuxedSection/MuxedSectionFormItem/Muxer";

const translations = defineMessages({
  fieldNameValidation: {
    id: "OutputURLFormItem.fieldNameValidation",
    defaultMessage: "Output name validation error - please fix it before add new output",
  },
  nameAlreadyExist: {
    id: "OutputURLFormItem.nameAlreadyExist",
    defaultMessage: "This output name already exist",
  },
});

const OutputURLFormItem = ({
  form,
  prefix,
  muxedOutput,
  isNewMuxedOutput,
  handleFinish,
  channelId,
  nodeChannels,
  detailView,
}) => {
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);
  const { formatMessage } = useIntl();
  const { validateFields, getFieldValue, setFieldsValue, setFieldValue, resetFields } = form;

  const [outputSectionVisible, setOutputSectionVisible] = useState(null);
  const [disableCondition, setDisableCondition] = useState(false);
  const numberOfOutputs = muxedOutput && muxedOutput.outputs ? muxedOutput.outputs.length : 0;

  const [muxerData, setMuxerData] = useState(null);

  const handleAddMuxedOutputsOutput = async () => {
    try {
      await validateFields([[OUTPUTS_TYPE.muxedOutputs, prefix[1], "name"]]);

      const defaultOutputValues = {
        ...DEFAULT_MUXED_OUTPUT_OUTPUT,
      };
      const muxedOutputOutputs = getFieldValue([...prefix, "outputs"]) || [];

      const formMuxedOutput = getFieldValue(prefix);

      setFieldValue([...prefix, "outputs", muxedOutputOutputs.length], {
        ...defaultOutputValues,
        outputName: `${formMuxedOutput.name}-output-${muxedOutputOutputs.length}`,
      });

      setOutputSectionVisible(NEW);
    } catch (error) {
      warningNotification(formatMessage)(translations.fieldNameValidation);
    }
  };

  const handleOKMuxedOutput = async () => {
    if (!disableCondition) {
      try {
        await validateFields();

        if (isNewMuxedOutput) {
          isNewMuxedOutput(prefix[1]);
        }

        const updatedFormData = getFieldValue();

        handleFinish(updatedFormData);
        setOutputSectionVisible(null);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error - formValidation", error);
      }
    }
  };

  const handleCancelMuxedOutput = () => {
    resetFields([[...prefix, "outputs"]]);
    setOutputSectionVisible(null);
  };

  const handleRemoveMuxedOutputOutput = async (outputName) => {
    const channelConfig = getFieldValue();
    const outputToRemove = muxedOutput.outputs.find((output) => output.outputName === outputName);
    const isQSMethodOutput = outputToRemove.type === QUICKSTREAM_METHOD_V2.outQSDirect.value;

    const parsedFormMuxedOutputOutputs = muxedOutput.outputs.filter((output) => output.outputName !== outputName);

    const muxedOutputs = getFieldValue([OUTPUTS_TYPE.muxedOutputs]);
    muxedOutputs[prefix[1]].outputs = parsedFormMuxedOutputOutputs;
    const updatedFormData = { ...channelConfig, muxedOutputs };

    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.UPDATE_CHANNEL,
        channelId,
        channelConfig: JSON.stringify(updatedFormData),
      },
    });

    setFieldsValue({
      muxedOutputs,
    });
    if (isQSMethodOutput) {
      await PlatformAPI.removeFingerprintFromChannel({ oldCloudIds: [outputToRemove?.cloudId] }, { errorNotification });
    }
    setOutputSectionVisible(null);
  };

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);
    const parsedOutputNames = outputNames && outputNames.filter((name) => name !== muxedOutput?.name);

    const duplicatedMuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedMuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  const isMuxedOutputOutputs = muxedOutput?.outputs && muxedOutput?.outputs.length > 0;

  return (
    <>
      <Col span={12}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "name"]}
          label={formatMessage(globalTranslations.name)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>
      <Col span={24}>
        <Card title={<FormattedMessage id="MuxedOutput.muxer" defaultMessage="Muxer" />}>
          <Muxer
            disabled={!!detailView}
            handleFinish={handleFinish}
            id={channelId}
            isNewMuxedOutput={isNewMuxedOutput}
            muxerData={muxerData}
            name={[...prefix, "muxer"]}
            prefix={[...prefix, "muxer"]}
            setMuxerData={setMuxerData}
            form={form}
          />
        </Card>
      </Col>
      <StyledColCenter span={24}>
        <CaretDownOutlined />
      </StyledColCenter>
      <Col span={24}>
        <Card title={<FormattedMessage id="MuxedOutput.outputs" defaultMessage="Outputs" />}>
          {!isMuxedOutputOutputs && detailView && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {muxedOutput?.outputs &&
            muxedOutput?.outputs.map((output, index) => {
              const handleClick = () => {
                validateFields();
                setOutputSectionVisible(output.outputId);
              };

              return (
                <Row gutter={24} key={output.outputId}>
                  <Col span={24}>
                    <StreamSourceElement
                      connectionType={output?.type}
                      destinationAddress={output?.destinationAddress}
                      destinationPort={output?.destinationPort}
                      localPort={output?.localPort}
                      handleClick={handleClick}
                      interfaceIp={output?.interfaceIp}
                      label={parseConnectionMethodName(output?.type)}
                      title={output?.outputName ? output?.outputName : parseConnectionMethodName(output?.type)}
                      cloudId={output?.cloudId}
                      incomplete={output?.incomplete}
                    />
                    {outputSectionVisible === output.outputId && (
                      <StyledModal
                        zIndex={100}
                        centered
                        title={
                          <FormattedMessage
                            id="MuxedSectionFormItem.editMuxedOutput"
                            defaultMessage="Edit Muxed Output"
                          />
                        }
                        open={outputSectionVisible === output.outputId}
                        onOk={handleOKMuxedOutput}
                        onCancel={handleCancelMuxedOutput}
                        forceRender
                        footer={detailView ? null : undefined}
                        okText={
                          <span>
                            <FormattedMessage id="general.save" defaultMessage="Save" />
                          </span>
                        }
                        okButtonProps={{ icon: <SaveOutlined /> }}
                        destroyOnClose
                      >
                        <OutputFormItem
                          channelId={channelId}
                          disabled={!!detailView}
                          getFieldValue={getFieldValue}
                          handleRemove={handleRemoveMuxedOutputOutput}
                          index={index}
                          initialValue={output}
                          isMuxedOutput
                          isNewMuxedOutput={isNewMuxedOutput}
                          name={output?.outputName}
                          outputType={OUTPUTS_TYPE.muxedOutputs}
                          prefix={[...prefix, "outputs"]}
                          setDisableCondition={setDisableCondition}
                          setFieldsValue={setFieldsValue}
                          form={form}
                        />
                      </StyledModal>
                    )}
                  </Col>
                </Row>
              );
            })}
          {!detailView && (
            <StyledAddButton type="dashed" icon={<PlusOutlined />} onClick={handleAddMuxedOutputsOutput}>
              <span>
                <FormattedMessage id="MuxedSectionFormItem.addOutput" defaultMessage="Add Output" />
              </span>
            </StyledAddButton>
          )}
          {outputSectionVisible === NEW && (
            <StyledModal
              zIndex={100}
              centered
              title={<FormattedMessage id="MuxedSectionFormItem.newMuxedOutput" defaultMessage="New Muxed Output" />}
              open={outputSectionVisible === NEW}
              onOk={handleOKMuxedOutput}
              onCancel={handleCancelMuxedOutput}
              okText={
                <span>
                  <FormattedMessage id="general.save" defaultMessage="Save" />
                </span>
              }
              okButtonProps={{ icon: <SaveOutlined /> }}
            >
              <OutputFormItem
                channelId={channelId}
                disabled={!!detailView}
                getFieldValue={getFieldValue}
                handleRemove={handleRemoveMuxedOutputOutput}
                index={numberOfOutputs}
                initialValue={OUT_UDP_DEFAULT}
                isNewMuxedOutput={isNewMuxedOutput}
                isMuxedOutput
                outputType={OUTPUTS_TYPE.muxedOutputs}
                prefix={[...prefix, "outputs"]}
                setDisableCondition={setDisableCondition}
                setFieldsValue={setFieldsValue}
                form={form}
              />
            </StyledModal>
          )}
        </Card>
      </Col>
    </>
  );
};

const StyledColCenter = styled(Col)`
  display: flex;
  justify-content: center;
  color: ${themeColor.orange};
  font-size: 40px;
`;

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledAddButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

OutputURLFormItem.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  channelId: PropTypes.string,
  isNewMuxedOutput: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  muxedOutput: PropTypes.object,
  nodeChannels: ImmutablePropTypes.map.isRequired,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

OutputURLFormItem.defaultProps = {
  detailView: null,
  channelId: null,
  muxedOutput: null,
};

export default OutputURLFormItem;
