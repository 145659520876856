import { createAction, handleActions } from "redux-actions";
import { Map } from "immutable";
import moment from "moment";
import { createSelector } from "reselect";

export const actions = {
  SET_PLAYOUT_PLAYLIST: createAction("PLAYOUT_PLAYLIST/SET_PLAYOUT_PLAYLIST"),
  UPDATE_PLAYOUT_PLAYLIST: createAction("PLAYOUT_PLAYLIST/UPDATE_PLAYOUT_PLAYLIST"),
  CLEAR_PLAYOUT_PLAYLIST: createAction("PLAYOUT_PLAYLIST/CLEAR_PLAYOUT_PLAYLIST"),
  CLEAR_PLAYOUT_PLAYLISTS: createAction("PLAYOUT_PLAYLIST/CLEAR_PLAYOUT_PLAYLISTS"),
};

const defaultState = new Map({});

const reducer = handleActions(
  {
    [actions.SET_PLAYOUT_PLAYLIST]: (state, { payload }) => {
      switch (payload.dataType) {
        case "initialData":
          return state
            .setIn([payload.channelId, "apiChannel"], payload?.apiChannel)
            .setIn([payload.channelId, "cgMode"], payload?.cgMode)
            .setIn([payload.channelId, "cgControl"], payload?.cgControl)
            .setIn([payload.channelId, "cgSource"], payload?.cgSource);
        case "cgStatus":
          return state
            .setIn([payload.channelId, "cgControlStatus"], payload?.cgControlStatus)
            .setIn([payload.channelId, "cgSourceStatus"], payload?.cgSourceStatus);
        case "elemPath":
          return state
            .setIn([payload.channelId, `elem-${payload?.elem}`], payload?.elem)
            .setIn([payload.channelId, `path-${payload?.elem}`], payload?.path);
        case "elemStatus":
          return state
            .setIn([payload.channelId, `elem-${payload?.elem}`], payload?.elem)
            .setIn([payload.channelId, `status-${payload?.elem}`], payload?.status);
        case "apiStatus":
          return state.setIn([payload.channelId, "connected"], payload?.connected);
        case "playlist":
          return state.setIn([payload.channelId, "playlist"], payload?.list);
        default:
          return state;
      }
    },
    [actions.CLEAR_PLAYOUT_PLAYLIST]: (state, { payload }) => {
      return state.delete(payload?.channelId);
    },
    [actions.CLEAR_PLAYOUT_PLAYLISTS]: () => defaultState,
  },
  defaultState
);

export const selectors = {
  getParams: (state, params) => params,
  getPlayoutPlaylist: (state, channelId) => {
    const playoutPlaylist = state.getIn(["nodePlayoutPlaylist", channelId, "playlist"]);

    return playoutPlaylist;
  },
  getPlayoutPlaylistApiChannel: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "apiChannel"]) || "N/A",
  getPlayoutPlaylistCgMode: (state, channelId) => state.getIn(["nodePlayoutPlaylist", channelId, "cgMode"]) || "N/A",
  getPlayoutPlaylistCgControl: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "cgControl"]) || "N/A",
  getPlayoutPlaylistCgSource: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "cgSource"]) || "N/A",
  getPlayoutPlaylistCgControlStatus: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "cgControlStatus"]) || "N/A",
  getPlayoutPlaylistCgSourceStatus: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "cgSourceStatus"]) || "N/A",
  getPlayoutPlaylistElemPath0: (state, channelId) => state.getIn(["nodePlayoutPlaylist", channelId, "path-0"]) || "N/A",
  getPlayoutPlaylistElemStatus0: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "status-0"]) || "N/A",
  getPlayoutPlaylistElemPath20: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "path-20"]) || "N/A",
  getPlayoutPlaylistElemStatus20: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "status-20"]) || "N/A",
  getPlayoutPlaylistElemPath21: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "path-21"]) || "N/A",
  getPlayoutPlaylistElemStatus21: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "status-21"]) || "N/A",
  getPlayoutPlaylistElemPath22: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "path-22"]) || "N/A",
  getPlayoutPlaylistElemStatus22: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "status-22"]) || "N/A",
  getPlayoutPlaylistApiConnected: (state, channelId) =>
    state.getIn(["nodePlayoutPlaylist", channelId, "connected"]) ? "connected" : "disconnected" || "N/A",
};

const formatDuration = (ms) => {
  if (!ms && ms !== 0) {
    return "unknown";
  }

  const duration = moment.duration(ms);
  const hours = String(duration.hours()).padStart(2, "0");
  const minutes = String(duration.minutes()).padStart(2, "0");
  const seconds = String(duration.seconds()).padStart(2, "0");
  const millis = String(duration.milliseconds()).padStart(3, "0");

  return `${hours}:${minutes}:${seconds}.${millis}`;
};

export const MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR = createSelector([selectors.getPlayoutPlaylist], (playoutPlaylist) => {
  const parsedPlayoutPlaylist =
    playoutPlaylist &&
    playoutPlaylist.map((item) => {
      const formattedDuration = formatDuration(item?.duration);
      const formattedTcIn = formatDuration(item?.tcIn);
      const formattedTcOut = formatDuration(item?.tcOut);
      const fileName = item?.videoFilePath.split("\\").pop();

      return {
        ...item,
        time: item.start && moment.utc(item.start).format("HH:mm:ss.SSS"),
        date: item.start && moment.utc(item.start).format("YYYY-MM-DD"),
        formattedDuration,
        formattedTcIn,
        formattedTcOut,
        fileName,
      };
    });

  return parsedPlayoutPlaylist;
});

export default reducer;
