import React, { useState } from "react";
import { Col, Card, Row, Modal, Button, Empty, Form, Select } from "antd";
import styled from "styled-components";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { PlusOutlined, CaretDownOutlined, SaveOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { PlatformAPI } from "../../lib/api";
import MQTTService from "../../services/mqtt";

import InputTextFiled from "../Fields/InputTextField";

import globalTranslations from "../../lib/translations";
import { max32characters, az09space, required } from "../../lib/utils/formValidation";
import parseConnectionMethodName from "../../lib/utils/parseConnectionMethodName";
import themeColor from "../../lib/style/theme";
import { OUTPUTS_TYPE, NEW, MESSAGE_TYPE, QUICKSTREAM_METHOD_V2 } from "../../lib/utils/constants";
import { DEFAULT_TRANSCODED_DESTINATION_URL } from "../../lib/utils/defaultValues/defaultTranscodedOutput";
import { warningNotification, errorNotification } from "../../lib/utils/notification";
import { PLAYOUT_DATA_SOURCE_SELECTOR } from "../../lib/utils/defaultValues/defaultPlayoutOutput";

import { selectors as NODE_SELECTORS } from "../../ducks/node";
import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";

import StreamSourceElement from "../StreamSourceElement";
import OutputFormItem from "../NodeChannelFormV5/OutputFormItem";
// eslint-disable-next-line max-len
import EnginePlayoutMuxer from "../NodeChannelFormV5/EnginePlayout/EnginePlayoutOutputsSection/EnginePlayoutMuxer";

const translations = defineMessages({
  fillAllRequiredField: {
    id: "PlayoutOutputURLFormItem.fillAllRequiredField",
    defaultMessage: "Please fill all required field",
  },
  fieldNameValidation: {
    id: "PlayoutOutputURLFormItem.fieldNameValidation",
    defaultMessage: "Output name validation error - please fix it before add new output",
  },
  nameAlreadyExist: {
    id: "PlayoutOutputURLFormItem.nameAlreadyExist",
    defaultMessage: "This output name already exist",
  },
  dataSource: {
    id: "PlayoutOutputURLFormItem.dataSource",
    defaultMessage: "Data source",
  },
});

const { Item } = Form;

function PlayoutOutputURLFormItem({ form, prefix, outputData, handleFinish, channelId, detailView, isNewOutput }) {
  const { formatMessage } = useIntl();
  const { validateFields, getFieldValue, setFieldsValue } = form;
  const cwid = useSelector(NODE_SELECTORS.getNodeCwid);
  const nodeChannels = useSelector(CHANNEL_SELECTORS.getChannels);

  const [outputSectionVisible, setOutputSectionVisible] = useState(null);

  const [disableCondition, setDisableCondition] = useState(false);
  const numberOfOutputs = outputData && outputData.urlDestinations ? outputData.urlDestinations.length : 0;

  const handleUrlDestination = async () => {
    try {
      await validateFields([["outputs", prefix[1], "name"]]);
      await validateFields();

      const outputs = getFieldValue(["outputs"]);

      const urlDestinations = getFieldValue([...prefix, "urlDestinations"]) || [];
      const outputName = getFieldValue([...prefix, "name"]);

      outputs[prefix[1]].urlDestinations[urlDestinations.length] = {
        ...DEFAULT_TRANSCODED_DESTINATION_URL,
        outputName: `Destination-${urlDestinations.length} ${outputName}`,
      };

      setFieldsValue({
        outputs,
      });

      setOutputSectionVisible(NEW);
    } catch (error) {
      warningNotification(formatMessage)(translations.fillAllRequiredField);
    }
  };

  const handleOKOutput = async () => {
    if (!disableCondition) {
      try {
        await validateFields();
        const updatedFormData = getFieldValue();

        if (isNewOutput) {
          isNewOutput(prefix[1]);
        }

        setOutputSectionVisible(null);
        handleFinish(updatedFormData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error - formValidation", error);
      }
    }
  };

  const handleCancelPlayoutOutput = (isNewDestination) => {
    if (!isNewDestination) {
      setOutputSectionVisible(null);

      return;
    }

    const outputs = getFieldValue(["outputs"]);
    const outputIndex = prefix[1];
    const parsedOutputs = outputs.map((output, index) => {
      if (index === outputIndex) {
        const parsedOutputDestinations = output.urlDestinations.slice(0, -1);

        return {
          ...output,
          urlDestinations: parsedOutputDestinations,
        };
      }

      return output;
    });

    setFieldsValue({ outputs: parsedOutputs });
    setOutputSectionVisible(null);
  };

  const handleRemoveDestination = async (outputName) => {
    const channelConfig = getFieldValue();
    const outputToRemove = outputData?.urlDestinations.find((output) => output.outputName === outputName);
    const isQSMethodOutput = outputToRemove.type === QUICKSTREAM_METHOD_V2.outQSDirect.value;

    const parsedFormOutputDataOutputs = outputData.urlDestinations.filter((output) => output.outputName !== outputName);

    const outputs = getFieldValue(["outputs"]);
    outputs[prefix[1]].urlDestinations = parsedFormOutputDataOutputs;
    const updatedFormData = { ...channelConfig, outputs };

    MQTTService.sendMessage({
      topic: `node/${cwid}/in`,
      message: {
        msgType: MESSAGE_TYPE.UPDATE_CHANNEL,
        channelId,
        channelConfig: JSON.stringify(updatedFormData),
      },
    });

    setFieldsValue({
      outputs,
    });

    if (isQSMethodOutput) {
      await PlatformAPI.removeFingerprintFromChannel({ oldCloudIds: [outputToRemove?.cloudId] }, { errorNotification });
    }
    setOutputSectionVisible(null);
  };

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);
    const parsedOutputNames = outputNames && outputNames.filter((name) => name !== outputData?.name);

    const duplicatedMuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedMuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  const isUrlDestinations = outputData?.urlDestinations && outputData?.urlDestinations.length > 0;

  return (
    <>
      <Col span={8}>
        <Item name={[...prefix, "dataSource"]} label={formatMessage(translations.dataSource)} rules={[required]}>
          <Select
            options={[
              PLAYOUT_DATA_SOURCE_SELECTOR.main,
              PLAYOUT_DATA_SOURCE_SELECTOR.live,
              PLAYOUT_DATA_SOURCE_SELECTOR.switcher,
            ]}
          />
        </Item>
      </Col>
      <Col span={8}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "name"]}
          label={formatMessage(globalTranslations.name)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>
      <Col span={24}>
        <Card title={<FormattedMessage id="PlayoutOutputURLFormItem.muxer" defaultMessage="Muxer" />}>
          <EnginePlayoutMuxer disabled={!!detailView} prefix={prefix} />
        </Card>
      </Col>
      <StyledColCenter span={24}>
        <CaretDownOutlined />
      </StyledColCenter>
      <Col span={24}>
        <Card title={<FormattedMessage id="PlayoutOutputURLFormItem.destinations" defaultMessage="Destinations" />}>
          {!isUrlDestinations && detailView && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {outputData?.urlDestinations &&
            outputData?.urlDestinations.map((output, index) => {
              const handleClick = () => {
                validateFields();
                setOutputSectionVisible(output.outputId);
              };

              return (
                <Row gutter={24} key={output.outputId}>
                  <Col span={24}>
                    <StreamSourceElement
                      connectionType={output?.type}
                      destinationAddress={output?.destinationAddress}
                      destinationPort={output?.destinationPort}
                      localPort={output?.localPort}
                      handleClick={handleClick}
                      interfaceIp={output?.interfaceIp}
                      label={parseConnectionMethodName(output?.type)}
                      title={output?.outputName ? output?.outputName : parseConnectionMethodName(output?.type)}
                      cloudId={output?.cloudId}
                      keepHours={output?.keepHours}
                      fileTimeSpan={output?.fileTimeSpan}
                    />
                    {outputSectionVisible === output.outputId && (
                      <StyledModal
                        zIndex={100}
                        centered
                        title={
                          <FormattedMessage
                            id="PlayoutOutputURLFormItem.editPlayoutOutput"
                            defaultMessage="Edit Playout Output"
                          />
                        }
                        open={outputSectionVisible === output.outputId}
                        onOk={handleOKOutput}
                        onCancel={() => handleCancelPlayoutOutput()}
                        forceRender
                        footer={detailView ? null : undefined}
                        okText={
                          <span>
                            <FormattedMessage id="general.save" defaultMessage="Save" />
                          </span>
                        }
                        okButtonProps={{ icon: <SaveOutlined /> }}
                      >
                        <OutputFormItem
                          channelId={channelId}
                          disabled={!!detailView}
                          handleRemove={handleRemoveDestination}
                          index={index}
                          initialValue={output}
                          name={output?.outputName}
                          outputType={OUTPUTS_TYPE.TRANSCODING}
                          prefix={[...prefix, "urlDestinations"]}
                          setDisableCondition={setDisableCondition}
                          form={form}
                        />
                      </StyledModal>
                    )}
                  </Col>
                </Row>
              );
            })}
          {!detailView && (
            <StyledAddButton type="dashed" icon={<PlusOutlined />} onClick={handleUrlDestination}>
              <span>
                <FormattedMessage id="PlayoutOutputURLFormItem.addDestination" defaultMessage="Add destination" />
              </span>
            </StyledAddButton>
          )}
          {outputSectionVisible === NEW && (
            <StyledModal
              zIndex={100}
              centered
              title={
                <FormattedMessage id="PlayoutOutputURLFormItem.newDestinations" defaultMessage="New destination" />
              }
              open={outputSectionVisible === NEW}
              onOk={handleOKOutput}
              onCancel={() => handleCancelPlayoutOutput(NEW)}
            >
              <OutputFormItem
                channelId={channelId}
                disabled={!!detailView}
                handleRemove={handleRemoveDestination}
                index={numberOfOutputs}
                initialValue={DEFAULT_TRANSCODED_DESTINATION_URL}
                outputType={OUTPUTS_TYPE.TRANSCODING}
                prefix={[...prefix, "urlDestinations"]}
                setDisableCondition={setDisableCondition}
                form={form}
              />
            </StyledModal>
          )}
        </Card>
      </Col>
    </>
  );
}

const StyledColCenter = styled(Col)`
  display: flex;
  justify-content: center;
  color: ${themeColor.orange};
  font-size: 40px;
`;

const StyledModal = styled(Modal)`
  min-width: 75%;
`;

const StyledAddButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

PlayoutOutputURLFormItem.propTypes = {
  detailView: PropTypes.bool,
  handleFinish: PropTypes.func.isRequired,
  channelId: PropTypes.string,
  outputData: PropTypes.object,
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  form: PropTypes.object.isRequired,
};

PlayoutOutputURLFormItem.defaultProps = {
  detailView: null,
  channelId: null,
  outputData: null,
};

export default PlayoutOutputURLFormItem;
