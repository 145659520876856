import React, { useCallback, useEffect } from "react";
import { Card, Switch } from "antd";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import MQTTService from "../../../../services/mqtt";

import useNodeConnection from "../../../../hooks/useNodeConnection";

import LoadingNodeData from "../../../../components/LoadingNodeData";

import { MESSAGE_TYPE, NODE_CONFIG_DATA } from "../../../../lib/utils/constants";
import { selectors as NODE_SELECTORS } from "../../../../ducks/node";

export default function SourceSwitchTooltip() {
  const isConnection = useNodeConnection();
  const { cwid } = useParams();

  const nodeInputSwitchTooltip = useSelector(NODE_SELECTORS.getNodeInputSwitchTooltip);
  const inputSwitchTooltipValue = nodeInputSwitchTooltip === "false" ? 0 : 1;

  useEffect(() => {
    if (isConnection && !nodeInputSwitchTooltip) {
      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.GET_CLOUD_CONFIG,
        },
      });
    }
  }, [isConnection, cwid, nodeInputSwitchTooltip]);

  const handleChange = useCallback(
    (switchValue) => {
      const parsedValue = switchValue ? "true" : "false";

      MQTTService.sendMessage({
        topic: `node/${cwid}/in`,
        message: {
          msgType: MESSAGE_TYPE.SET_CLOUD_CONFIG,
          key: NODE_CONFIG_DATA.CHANGE_INPUT_SWITCH_CONFIRMATION,
          value: parsedValue,
        },
      });
    },
    [cwid]
  );

  if (!isConnection) {
    return <LoadingNodeData />;
  }

  return (
    <Card title={<FormattedMessage id="SourceSwitchTooltip.title" defaultMessage="Switch input source confirmation" />}>
      <Switch
        checkedChildren={<FormattedMessage id="general.enabled" defaultMessage="Enabled" />}
        unCheckedChildren={<FormattedMessage id="general.disabled" defaultMessage="Disabled" />}
        value={inputSwitchTooltipValue}
        onChange={handleChange}
      />
    </Card>
  );
}
