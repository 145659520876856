import {
  MPEG2PROFILE,
  SELECT_DEFAULT_AUTO,
  SELECT_STREAM_CODEC,
  X264_PRESET,
  X264_TUNE,
  X264_PROFILE,
  X264_LEVEL,
  OUTPUT_RTMP_PIX_FORMAT,
  TRANSCODING_NVIDIA_PIX_FORMAT,
} from "./constants";

export default ({ codecType, setFieldValue, prefix, isOutputRtmp }) => {
  const isMpeg2videoType = codecType === SELECT_STREAM_CODEC[1].value;
  const isOpenH264Type = codecType === SELECT_STREAM_CODEC[2].value;
  const isH264Type = codecType === SELECT_STREAM_CODEC[3].value;
  const isNvidia264Type = codecType === "Nv264";
  const isNvidia265Type = codecType === "Nv265";

  if (isNvidia264Type) {
    const codec = {
      convert: false,
      pid: 0,
      videoCodec: {
        type: codecType,
        changeGOP: false,
        interlaced: 0,
        nv264Coder: SELECT_DEFAULT_AUTO.value,
        nv264Level: SELECT_DEFAULT_AUTO.value,
        nv264Preset: "fast",
        nv264Profile: "high",
        nv264RateControl: "cbr",
        nv264RCLookahead: 0,
        nv264SelectGPU: -1,
        nv264StrictGOP: false,
      },
      videoFormat: {
        pixFormat: TRANSCODING_NVIDIA_PIX_FORMAT[0].value,
      },
    };
    setFieldValue(prefix, codec);
  }

  if (isNvidia265Type) {
    const codec = {
      convert: false,
      pid: 0,
      videoCodec: {
        type: codecType,
        changeGOP: false,
        interlaced: 0,
        nv265Level: SELECT_DEFAULT_AUTO.value,
        nv265Preset: "fast",
        nv265RCLookahead: 0,
        nv265Tier: "high",
        nv265RateControl: "cbr",
        nv265SelectGPU: -1,
        nv265StrictGOP: false,
      },
      videoFormat: {
        pixFormat: TRANSCODING_NVIDIA_PIX_FORMAT[0].value,
      },
    };
    setFieldValue(prefix, codec);
  }

  if (isMpeg2videoType) {
    const codec = {
      convert: false,
      pid: 0,
      videoCodec: {
        mpeg2Profile: MPEG2PROFILE[0].value,
        interlaced: 0,
        changeGOP: false,
        type: codecType,
      },
      videoFormat: {
        resize: SELECT_DEFAULT_AUTO.value,
        pixFormat: SELECT_DEFAULT_AUTO.value,
      },
    };
    setFieldValue(prefix, codec);
  }

  if (isOpenH264Type) {
    const codec = {
      convert: false,
      pid: 0,
      videoCodec: {
        interlaced: 0,
        changeGOP: false,
        type: codecType,
      },
      videoFormat: {
        resize: SELECT_DEFAULT_AUTO.value,
        pixFormat: isOutputRtmp ? OUTPUT_RTMP_PIX_FORMAT[0].value : SELECT_DEFAULT_AUTO.value,
      },
    };
    setFieldValue(prefix, codec);
  }

  if (isH264Type) {
    const codec = {
      convert: false,
      pid: 0,
      videoCodec: {
        mpeg2Profile: MPEG2PROFILE[0].value,
        interlaced: 0,
        changeGOP: false,
        x264Preset: X264_PRESET[2].value,
        x264Tune: X264_TUNE[0].value,
        x264Profile: X264_PROFILE[3].value,
        x264Level: X264_LEVEL[0].value,
        x264VbvBufsize: 4,
        type: codecType,
      },
      videoFormat: {
        resize: SELECT_DEFAULT_AUTO.value,
        pixFormat: isOutputRtmp ? OUTPUT_RTMP_PIX_FORMAT[0].value : SELECT_DEFAULT_AUTO.value,
      },
    };
    setFieldValue(prefix, codec);
  }
};
