import React from "react";
import { Col, Divider } from "antd";
import { useIntl, defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { max32characters, az09space, required } from "../../lib/utils/formValidation";

import { selectors as CHANNEL_SELECTORS } from "../../ducks/nodeChannels";

import globalTranslations from "../../lib/translations";
import InputTextFiled from "../Fields/InputTextField";
import InputNumberField from "../Fields/InputNumberField";
// import NDISelectStream from "../NdiSelectStream";
import NDIVideoFormat from "../NDIVideoFormat";
import NDIAudioFormats from "../NDIAudioFormats";

const translations = defineMessages({
  streamName: {
    id: "TranscodingOutputNDIFormItemV5.streamName",
    defaultMessage: "Stream name",
  },
  audioStream: {
    id: "TranscodingOutputNDIFormItemV5.audioStream",
    defaultMessage: "Audio stream",
  },
  nameAlreadyExist: {
    id: "TranscodingOutputNDIFormItemV5.nameAlreadyExist",
    defaultMessage: "This output name already exist",
  },
});

const TranscodingOutputNDIFormItemV5 = ({ prefix, detailView, channelId, initialOutputName }) => {
  const { formatMessage } = useIntl();
  const nodeChannels = useSelector(CHANNEL_SELECTORS.getChannels);

  const uniqueValidator = (rule, value) => {
    const outputNames = nodeChannels.getIn([`${channelId}`, "outputs", "outputsNames"]);

    const parsedOutputNames = outputNames && outputNames.filter((outName) => outName !== initialOutputName);
    const duplicatedMuxedOutputName = parsedOutputNames && parsedOutputNames.includes(value);

    if (duplicatedMuxedOutputName) {
      return Promise.reject(formatMessage(translations.nameAlreadyExist));
    }

    return Promise.resolve();
  };

  return (
    <>
      <Col span={12}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "name"]}
          label={formatMessage(globalTranslations.name)}
          rules={[{ validator: uniqueValidator }, max32characters, az09space, required]}
        />
      </Col>
      <Divider />
      <Col span={12}>
        <InputTextFiled
          disabled={detailView}
          name={[...prefix, "streamName"]}
          label={formatMessage(translations.streamName)}
          rules={[max32characters]}
        />
      </Col>

      <NDIVideoFormat prefix={[...prefix, "videoFormat"]} disabled={detailView} />
      <NDIAudioFormats prefix={[...prefix, "audioFormat"]} disabled={detailView} />
      <InputNumberField name={[...prefix, "outputId"]} label="outputId" disabled={detailView} hidden />
    </>
  );
};

TranscodingOutputNDIFormItemV5.propTypes = {
  prefix: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  detailView: PropTypes.bool,
  channelId: PropTypes.string.isRequired,
  initialOutputName: PropTypes.string,
};

TranscodingOutputNDIFormItemV5.defaultProps = {
  detailView: false,
  initialOutputName: null,
};

export default TranscodingOutputNDIFormItemV5;
